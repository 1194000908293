import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import FavoriteIcon from '@material-ui/icons/Favorite';
import UpdateIcon from '@material-ui/icons/Update';
import {
  InputAdornment,
  Badge,
  Dialog,
  List,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Chip,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';
import { Search } from '@material-ui/icons';
import LayoutWrapper from '../../components/layout';
import LocalizedLink from '../../utils/localized-link';
import { withForceUpdate } from '../../i18n/with-force-update';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';

const LANGS = { '5e63c50b529046001710f0ac': 'en', '5e63c506529046001710f0ab': 'fr' };

const useStyles = makeStyles((theme) => ({
  skillsContainer: {},
  singleSkill: {
    // border: "1px solid #F1F1F1",
    padding: '10px',
    margin: '4px',
    borderRadius: '4px',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '150px',
    minHeight: '150px',
    cursor: `pointer`,
  },
  belovedSkill: {
    background: `linear-gradient(134deg, #d4a730 50%, #dfac43 50%)`,
    fontWeight: `bold`,
  },
  belovedColor: {
    // color: `#dfac47`,
    padding: `5px`,
    borderRadius: `4px`,
  },
  textField: {
    display: 'flex',
    borderColor: '#F1F1F1',
    color: '#F1F1F1',
    '&:focus': {
      display: 'none',
      borderColor: '#dfac23',
    },
    margin: '30px auto',
    maxWidth: `100%`,
    width: `550px`,
  },
  skillLogo: {
    width: '100px',
    margin: `auto`,
    height: '100px',
    display: 'flex',
  },
  skillTitle: {
    marginTop: '10px',
  },
  hidden: {
    display: 'none',
  },
  searchFieldIcon: {},
  closeButton: {},
}));

const SkillsPage = ({ t, pageContext: { language } }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState(null);

  const handleClose = (value) => {
    setOpen(false);
    setSelectedSkill(null);
  };

  const data = useStaticQuery(
    graphql`
      query allSkillsQuery {
        ...Skills
      }
    `,
    { variables: { language } }
  );

  const [searchTerm, setSearchTerm] = useState('');

  const titleMatch = (val) => {
    if (searchTerm.length === 0) {
      return false;
    }

    return !val.toUpperCase().includes(searchTerm.toUpperCase());
  };

  React.useEffect(() => {
    if (window.location.hash) {
      const slug = window.location.hash.replace('#', '');
      let requestedSkill = null;
      data.skills.group.every((currentGroup) => {
        currentGroup.edges.every((elt) => {
          if (elt.node.slug === slug) {
            requestedSkill = elt.node;
            return false;
          }
          return true;
        });
        if (requestedSkill) {
          return false;
        }
        return true;
      });
      if (requestedSkill) {
        setOpen(true);
        setSelectedSkill(requestedSkill);
      }
    }
  }, [data]);

  const scrollTo = (id) => {
    const yOffset = -90;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <LayoutWrapper title={t('Skills')} showTitle lang={language}>
      <Box textAlign="center">
        <span>{t('Click any skill to discover associated experiences !')}</span>
        <br />
        {/* <i>
          <b className={classnames(classes.belovedColor, classes.belovedSkill)}>{t('Highlighted items')}</b>
          &nbsp;
          {t("are the ones I'm currently in love with !")}
        </i> */}
      </Box>
      {/* <TextField
        id="outlined-search"
        placeholder={t('Search a term')}
        type="search"
        margin="dense"
        sx={{
          '& label.Mui-focused': {
            color: 'green',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
          },
          '& .MuiOutlinedInput-root': {
            color: 'white',
            '& fieldset': {
              borderColor: '#f1F1F1',
            },
            '&:hover fieldset': {
              borderColor: '#dfac23',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#dfac23',
            },
          },
          display: 'flex',
          borderColor: '#F1F1F1',
          color: '#F1F1F1',
          '&:focus': {
            display: 'none',
            borderColor: '#dfac23',
          },
          margin: '30px auto',
          maxWidth: `100%`,
          width: `550px`,
        }}
        variant="outlined"
        onChange={(event) => setSearchTerm(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="end" sx={{ marginRight: '10px', color: '#dfab23' }}>
              <Search />
            </InputAdornment>
          ),
        }}
      /> */}

      <Grid
        container
        direction="row"
        sx={{
          flexWrap: 'wrap',
          margin: '20px 0',
        }}
        spacing={1}
        alignItems="center"
        justifyContent="center"
      >
        {data.skills.group.map((grp) => (
          <Grid item key={`chip-grp-${grp.fieldValue}`} onClick={() => scrollTo(`skill-group-${grp.fieldValue}`)}>
            <Chip label={t(grp.fieldValue)} clickable />
          </Grid>
        ))}
      </Grid>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          alignItems: `flex-start`,
          justifyContent: `flex-start`,
        }}
      >
        {data.skills.group.map((grp) => (
          <Box key={`grp-${grp.fieldValue}`} sx={{ margin: '30px 0' }} id={`skill-group-${grp.fieldValue}`}>
            <h3>{t(grp.fieldValue)}</h3>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: `flex-start`,
                justifyContent: `flex-start`,
              }}
            >
              {grp.edges.map((skillNode) => (
                <Box
                  key={skillNode.node.name}
                  onClick={() => {
                    setOpen(true);
                    setSelectedSkill(skillNode.node);
                  }}
                >
                  <span
                    id={skillNode.node.slug}
                    css={{
                      display: `block`,
                      position: `relative`,
                      top: `-250px`,
                      visibility: `hidden`,
                    }}
                  />
                  <Badge
                    overlap="circular"
                    badgeContent={skillNode.node.actualLike && <FavoriteIcon sx={{ color: '#dfac47' }} />}
                    // badgeContent={skillNode.node.experiences.filter((exp) => exp.locale === language).length}
                    sx={{ background: 'none' }}
                    className={classnames(classes.singleSkill, {
                      [classes.hidden]: titleMatch(skillNode.node.name),
                      // [classes.belovedSkill]: skillNode.node.actualLike,
                    })}
                  >
                    {skillNode.node.skillThumbnail && (
                      <div className={classes.skillLogo} css={{ width: `75px` }}>
                        <GatsbyImage
                          alt={skillNode.node.name}
                          imgStyle={{ objectFit: 'contain' }}
                          image={getImage(skillNode.node.skillThumbnail.localFile)}
                        />
                      </div>
                    )}
                    <div className={classes.skillTitle}>{skillNode.node.name}</div>
                    <Box sx={{ textTransform: 'none', fontSize: '13px', textAlign: 'center' }}>
                      {skillNode.node.experiences.filter((exp) => exp.locale === language).length} {t('projects')}
                    </Box>
                  </Badge>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
      {selectedSkill && (
        <Dialog aria-labelledby="simple-dialog-title" onClose={handleClose} open={open}>
          <DialogTitle id="simple-dialog-title">
            {selectedSkill.name}
            <IconButton
              aria-label="close"
              sx={{ position: 'absolute', right: '8px', top: '8px', color: '#9e9e9e' }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {selectedSkill.experiences.filter((exp) => exp.locale === language).length === 0
              ? t(
                  'There are no associated experiences with this skill. It means I may have practiced it in confidential / unpublished projects.'
                )
              : t(`Click any of the experiences below to learn more`)}
            <List>
              {selectedSkill.experiences
                .filter((exp) => exp.locale === language)
                .sort((a, b) => {
                  if (!a.toDate) {
                    return -1;
                  }
                  if (!b.toDate) {
                    return -1;
                  }
                  return Date.parse(b.toDate) - Date.parse(a.toDate);
                })
                .map((exp) => (
                  <LocalizedLink
                    key={exp.name}
                    style={{
                      padding: '10px 0',
                      fontFamily: `LatoLatinWebRegular`,
                      border: `none`,
                      background: `none`,
                      display: `block`,
                      position: 'relative',
                    }}
                    to={`/about/experiences#${exp.slug}`}
                  >
                    {exp && exp.experienceThumbnail && (
                      <GatsbyImage
                        imgStyle={{ objectFit: 'cover' }}
                        image={getImage(exp.experienceThumbnail.localFile)}
                        alt={exp.name}
                      />
                    )}
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: '55px',
                        background: 'rgba(0,0,0,0.5)',
                      }}
                    />
                    <Box
                      sx={{
                        '&::after': {
                          content: "''",
                          width: '50px',
                          height: '2px',
                          background: '#dfac47',
                          position: 'absolute',
                          bottom: '15px',
                          left: '10px',
                        },
                        fontFamily: 'LatoLatinWeb',
                        color: 'white',
                        height: '50px',
                        fontSize: '22px',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        paddingLeft: '10px',
                      }}
                    >
                      {exp.name}
                    </Box>
                  </LocalizedLink>
                ))}
            </List>
          </DialogContent>
        </Dialog>
      )}
    </LayoutWrapper>
  );
};

export default withForceUpdate(SkillsPage);
